.disabled{
    opacity: 0.5 !important;
    pointer-events: none;
}

.ant-btn-primary{
    background-color: black !important;
}

.customStyling #h3Container h3{
    padding: 0 !important;
    margin: 0 !important
}

.disabled-link{
    background: #fbf4f4 !important;
    pointer-events: none;
}